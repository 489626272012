<template>
    <section class="apr-section">
        <div class="container px-4 xl:px-0 mx-auto">
            <h2 v-if="blok.Title" class="apr-section-title">{{ blok.Title }}</h2>
            <div v-if="blok.Subtitle" class="apr-section-subtitle">{{ blok.Subtitle }}</div>
            <ClientOnly>
                <div v-if="showSlider" class="-mx-3" :class="`top-locations-carousel-${blok.Layout}`">
                    <AprCarousel
                        v-if="desktopLocations"
                        :slider-items="desktopLocations"
                        :slider-options="sliderOptions"
                    >
                        <template #default="{ item }">
                            <NuxtLink
                                v-for="location in item"
                                :key="location._uid"
                                :to="getInternalLink(location.Link)"
                                external
                                class="apr-top-location"
                            >
                                <NuxtImg
                                    v-if="location.Image?.filename"
                                    :src="location.Image?.filename"
                                    :title="location.Image?.title"
                                    :alt="location.Image?.alt"
                                    provider="storyblok"
                                    format="webp"
                                    loading="lazy"
                                    class="apr-top-location__image"
                                ></NuxtImg>
                                <div class="apr-top-location__text">{{ location.ImageTitle }}</div>
                            </NuxtLink>
                        </template>
                    </AprCarousel>
                </div>
                <template v-else>
                    <div class="flex flex-col gap-2 items-center">
                        <NuxtLink
                            v-for="location in mobileLocations"
                            :key="location._uid"
                            :to="getInternalLink(location.Link)"
                            external
                            class="apr-top-location"
                        >
                            <NuxtImg
                                v-if="location.Image?.filename"
                                :src="location.Image?.filename"
                                :title="location.Image?.title"
                                :alt="location.Image?.alt"
                                provider="storyblok"
                                format="webp"
                                loading="lazy"
                                class="apr-top-location__image"
                            ></NuxtImg>
                            <div class="apr-top-location__text">{{ location.ImageTitle }}</div>
                        </NuxtLink>
                    </div>
                    <div v-if="blok.Locations.length > 3" class="text-center mt-6">
                        <ShowMoreBtn
                            :is-opened="allLocationsShown"
                            show-text="Show more locations"
                            hide-text="Show less locations"
                            @click="allLocationsShown = !allLocationsShown"
                        />
                    </div>
                </template>
            </ClientOnly>
        </div>
    </section>
</template>

<script setup lang="ts">
import { useWindowSize } from '@vueuse/core';
import AprCarousel from './AprCarousel.vue';
import type { ITopLocations } from '~/types/TopLocations';
const { blok } = defineProps<{ blok: ITopLocations }>();

// Transform items for new layout
function transformArray(arr) {
    const result = [];
    for (let i = 0; i < arr.length; i++) {
        if (i % 3 === 0 && i + 1 < arr.length) {
            // Group the current and next element in a sub-array
            result.push([arr[i], arr[i + 1]]);
            i++; // Increment i to skip the next element since it's already grouped
        } else {
            // Add the current element as a single item
            result.push([arr[i]]);
        }
    }
    return result;
}
const locationsMultipleImages = computed(() => transformArray([...blok.Locations]));
const locationsSingleImages = computed(() => [...blok.Locations].map((el) => [el]));

const desktopLocations = computed(() =>
    blok.Layout === 'single' ? locationsSingleImages.value : locationsMultipleImages.value
);

const mobileLocations = computed(() => (allLocationsShown.value ? blok.Locations : blok.Locations.slice(0, 3)));

const { width } = useWindowSize();

// SLIDER
const sliderOptions = ref({
    slidesCount: {
        xl: 3,
        lg: 3,
        md: 2,
        sm: 1,
    },
});
import screens from '#tailwind-config/theme/screens';
const screenMd = Number(screens.md.slice(0, -2));

const showSlider = computed(() => width.value >= screenMd);
const getItemsToShow = 3;
const allLocationsShown = ref(false);
</script>

<style lang="postcss" scoped>
.top-locations-carousel-multiple {
    :deep(.carousel) {
        .carousel__slide {
            @apply grid aspect-[20/33];
            grid-auto-flow: column;
            gap: 24px;
            &:nth-child(4n + 1) {
                grid-template-rows: 2fr 1fr;
                & > .apr-top-location:only-child {
                    @apply row-span-3;
                }
            }
            &:nth-child(2n + 2),
            &:nth-child(2n + 4) {
                grid-template-rows: 1fr;
            }
            &:nth-child(4n + 3) {
                grid-template-rows: 1fr 2fr;
                & > .apr-top-location:only-child {
                    @apply row-span-3;
                }
            }
        }
    }
}
:deep(.carousel) {
    .carousel__next,
    .carousel__prev {
        @apply top-1/2 -mt-4 sm:top-1/2;
    }
}
.apr-top-location {
    @apply h-full w-full relative rounded-lg overflow-hidden aspect-[7/3] sm:aspect-auto;
    &:before {
        content: '';
        @apply inset-0 absolute bg-cover z-10 pointer-events-none;
        background: linear-gradient(0deg, rgba(30, 29, 29, 0.18) 0%, rgba(30, 29, 29, 0.18) 100%);
    }
    &:hover {
        .apr-top-location__image {
            @apply scale-105;
        }
    }
    &__image {
        @apply h-full w-full object-cover transition-transform;
    }
    &__text {
        @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 z-20 text-text-titanium text-xl sm:text-3xl xl:text-4xl font-bold uppercase text-center;
    }
}
</style>
